import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppBar } from '@mui/material';
import { Box } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { Divider } from '@mui/material';
import { Drawer } from '@mui/material';
import { IconButton } from '@mui/material';
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Typography } from '@mui/material';
import { Mail as MailIcon } from '@mui/icons-material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Home as HomeIcon } from '@mui/icons-material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { TravelExplore as VisitWebsideIcon } from '@mui/icons-material';
import { AddRoad } from '@mui/icons-material';
import styled from 'styled-components';

const drawerWidth = 240;

const StyledBox = styled(Box)`
    display: flex;
    height: 100%;
    width: 100%;
`;

const MainContainer = styled(Box)`
    flex-grow: 1;
    width: calc(100% - ${drawerWidth}px);
    margin-top: 56px;
    // padding: 20px;
`;

const StyledListItemIcon = styled(ListItemIcon)<any>`
    color: ${({ theme, $selected }) =>
        $selected ? theme.palette.primary.main : theme.palette.secondary.main} !important;
`;

const enum UrlType {
    RELATIVE = 'relative',
    ABSOLUTE = 'absolute',
}

type URL = {
    type: UrlType;
    path: string;
};

type MenuItem = {
    label: string;
    url: URL;
    icon: JSX.Element;
    disabled: boolean;
};

interface Props {}

const Menu = (props: Props) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState<string | undefined>(undefined);
    const location = useLocation();
    const navigate = useNavigate();

    const mainMenuItems: MenuItem[] = [
        {
            label: 'Home',
            url: { type: UrlType.RELATIVE, path: 'home' },
            icon: <HomeIcon style={{ color: 'currentColor' }} />,
            disabled: false,
        },
        {
            label: 'Weld Lookup',
            url: { type: UrlType.RELATIVE, path: 'weld-lookup' },
            icon: <AddRoad />,
            disabled: true,
        },
        {
            label: 'Weld Defects',
            url: { type: UrlType.RELATIVE, path: 'weld-defects' },
            icon: <AddRoad />,
            disabled: false,
        },
        {
            label: 'Rail Markings',
            url: { type: UrlType.RELATIVE, path: 'rail-markings' },
            icon: <AddRoad />,
            disabled: false,
        },
        {
            label: 'Rail Extension',
            url: { type: UrlType.RELATIVE, path: 'rail-extension' },
            icon: <AddRoad />,
            disabled: false,
        },
    ];

    const secondaryMenuItems: MenuItem[] = [
        {
            label: 'Settings',
            url: { type: UrlType.RELATIVE, path: 'settings' },
            icon: <SettingsIcon />,
            disabled: false,
        },
        {
            label: 'Contact Us',
            url: { type: UrlType.RELATIVE, path: 'contact-us' },
            icon: <MailIcon />,
            disabled: false,
        },
        {
            label: 'Visit Website',
            url: { type: UrlType.ABSOLUTE, path: 'https://www.goldschmidt.com/en/' },
            icon: <VisitWebsideIcon />,
            disabled: false,
        },
    ];

    const handleMenuItemClick = (menuItem: MenuItem) => {
        setMobileOpen(false);
        switch (menuItem.url.type) {
            case UrlType.RELATIVE:
                navigate(menuItem.url.path);
                break;
            case UrlType.ABSOLUTE:
                window.open(menuItem.url.path, '_blank');
                break;
        }
    };

    useEffect(() => {
        const pathLastElement = location.pathname.split('/').slice(-1)[0];
        let selectedMenuItem = mainMenuItems.find((menuItem: MenuItem) => menuItem.url.path === pathLastElement);
        if (!selectedMenuItem) {
            selectedMenuItem = secondaryMenuItems.find((menuItem: MenuItem) => menuItem.url.path === pathLastElement);
        }
        setSelectedMenuItem(selectedMenuItem?.url?.path);
    }, [location]);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {mainMenuItems.map((menuItem, index) => (
                    <ListItem key={menuItem.label} disablePadding>
                        <ListItemButton
                            disabled={menuItem.disabled}
                            onClick={() => handleMenuItemClick(menuItem)}
                            selected={menuItem.url.path === selectedMenuItem}
                        >
                            <StyledListItemIcon $selected={menuItem.url.path === selectedMenuItem}>
                                {menuItem.icon}
                            </StyledListItemIcon>
                            <ListItemText primary={menuItem.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {secondaryMenuItems.map((menuItem, index) => (
                    <ListItem key={menuItem.label} disablePadding>
                        <ListItemButton
                            disabled={menuItem.disabled}
                            onClick={() => handleMenuItemClick(menuItem)}
                            selected={menuItem.url.path === selectedMenuItem}
                        >
                            <StyledListItemIcon $selected={menuItem.url.path === selectedMenuItem}>
                                {menuItem.icon}
                            </StyledListItemIcon>
                            <ListItemText primary={menuItem.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <StyledBox>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Thermit
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <MainContainer component="main">
                {/* <Toolbar /> */}
                <Outlet />
            </MainContainer>
        </StyledBox>
    );
};

export default Menu;
