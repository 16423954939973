import { Navigate } from 'react-router-dom';
import HomePage from './components/pages/HomePage/HomePage';
import ContactUsPage from './components/pages/ContactUsPage/ContactUsPage';
import Menu from './components/Menu/Menu';
import SettingsPage from './components/pages/SettingsPage/SettingsPage';
import RailExtensionPage from './components/pages/RailExtensionPage/RailExtensionPage';
import RailMarkingsPage from './components/pages/RailMarkingsPage/RailMarkingsPage';
import RailMarkingDetailsPage from './components/pages/RailMarkingDetailsPage/RailMarkingDetailsPage';
import WeldDefectsPage from './components/pages/WeldDefectsPage/WeldDefectsPage';
import WeldDefectDetailsPage from './components/pages/WeldDefectDetailsPage/WeldDefectDetailsPage';

const routesConfig = [
    {
        path: '/',
        element: <Menu />,
        children: [
            {
                path: '/',
                element: <Navigate to="/home" replace />,
            },
            {
                path: '/home',
                element: <HomePage />,
            },
            {
                path: '/weld-defects',
                element: <WeldDefectsPage />,
            },
            {
                path: '/weld-defects/:weldDefectId',
                element: <WeldDefectDetailsPage />,
            },
            {
                path: '/rail-markings',
                element: <RailMarkingsPage />,
            },
            {
                path: '/rail-markings/:markingId',
                element: <RailMarkingDetailsPage />,
            },
            {
                path: '/rail-extension',
                element: <RailExtensionPage />,
            },
            {
                path: '/contact-us',
                element: <ContactUsPage />,
            },
            {
                path: '/settings',
                element: <SettingsPage />,
            },
        ],
    },
];

export default routesConfig;
