import styled from 'styled-components';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`;

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Label = styled.div`
    font-size: 18px;
    min-width: 80px;
    color: #333333;
`;

const Value = styled.div`
    font-size: 18px;
    font-weight: bold;
    white-space: pre-wrap;
    font-weight: 400;
    color: #333333;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: flex-start;
    justify-content: flex-start;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    align-items: flex-start;
`;

const StyledLink = styled.a`
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;

    &:visited {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: none;
    }

    &:link {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: none;

        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    &:active {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: none;

        text-decoration: none;
    }
`;

const ContactUsPage = () => {
    const info = [
        { label: 'Phone', value: <StyledLink href="tel:+441708522626">+44 1708 522626</StyledLink> },
        {
            label: 'Website',
            value: (
                <StyledLink href="https://www.thermit-welding.com" target="_blank" rel="noreferrer">
                    www.thermit-welding.com
                </StyledLink>
            ),
        },
        { label: 'Email', value: <StyledLink href="mailto:tw@goldschmidt.com">tw@goldschmidt.com</StyledLink> },
        {
            label: 'Address',
            value: `Thermit Welding (GB) Ltd\n87 Ferry Lane\nRainham, Essex\nRM13 9YH`,
        },
    ];

    return (
        <Page>
            <Section>
                <StyledImage src="/img/gol-logo.svg" alt="Logo" />
                <Title>Contact Us</Title>
                <DetailsContainer>
                    {info.map((item) => (
                        <div key={item.label}>
                            <Row>
                                <Label>{item.label}:</Label>
                                <Value>{item.value}</Value>
                            </Row>
                        </div>
                    ))}
                </DetailsContainer>
            </Section>
        </Page>
    );
};

export default ContactUsPage;
