import styled from '@emotion/styled';
import RailMarkingItem from './WeldDefectItem/WeldDefectItem';
import { useNavigate } from 'react-router-dom';
import WeldDefectConnector, { WeldDefect } from '../../../connectors/WeldDefectConnector';
import { useEffect, useState } from 'react';
import WeldDefectItem from './WeldDefectItem/WeldDefectItem';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const Subtitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const HeaderSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const BodySection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;
    gap: 15px;
    overflow-y: auto;
    padding: 0 20px 20px 20px;
`;

const WeldDefectsPage = () => {
    const navigate = useNavigate();
    const [weldDefects, setWeldDefects] = useState<WeldDefect[]>([]);

    useEffect(() => {
        WeldDefectConnector.getWeldDefects().then((fetchedWeldDefects) => {
            setWeldDefects(fetchedWeldDefects);
        });
    }, []);

    return (
        <Page>
            <HeaderSection>
                <StyledImage src="/img/gol-logo.svg" alt="Logo" />
                <Title>Weld Defects</Title>
            </HeaderSection>
            <BodySection>
                {weldDefects.map((weldDefect) => (
                    <WeldDefectItem
                        key={weldDefect.id}
                        weldDefect={weldDefect}
                        onClick={() => {
                            navigate(`/weld-defects/${weldDefect.id}`);
                        }}
                    />
                ))}
            </BodySection>
        </Page>
    );
};

export default WeldDefectsPage;
