import styled from '@emotion/styled';
import RailMarkingItem from './RailMarkingItem/RailMarkingItem';
import { useNavigate } from 'react-router-dom';
import MarkingConnector, { Marking } from '../../../connectors/MarkingConnector';
import { useEffect, useState } from 'react';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const Subtitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const HeaderSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const BodySection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;
    gap: 15px;
    overflow-y: auto;
    padding-bottom: 20px;
`;

const RailMarkingsPage = () => {
    const navigate = useNavigate();
    const [markings, setMarkings] = useState<Marking[]>([]);

    useEffect(() => {
        MarkingConnector.getMarkings().then((fetchedMarkings) => {
            setMarkings(fetchedMarkings);
        });
    }, []);

    return (
        <Page>
            <HeaderSection>
                <StyledImage src="/img/gol-logo.svg" alt="Logo" />
                <Title>Rail Markings</Title>
            </HeaderSection>
            <BodySection>
                {markings.map((marking) => (
                    <RailMarkingItem
                        key={marking.id}
                        railMarking={marking}
                        onClick={() => {
                            navigate(`/rail-markings/${marking.id}`);
                        }}
                    />
                ))}
            </BodySection>
        </Page>
    );
};

export default RailMarkingsPage;
