import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MarkingConnector, { Marking } from '../../../connectors/MarkingConnector';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const MarkingImage = styled.img`
    width: 80%;
    max-width: 400px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const BodySection = styled(Section)`
    width: 100%;
    padding-top: 0;
    overflow-y: auto;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const Label = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const StrongLabel = styled(Label)`
    font-weight: bold;
`;

const Value = styled.div`
    display: flex;
    align-items: center;
    color: #e3140e;
`;

const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const DetailsContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
`;

const BackButton = styled.button`
    position: absolute;
    right: 15px;
    top: 60px;
    border: none;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
`;

const RailMarkingDetailsPage = () => {
    const navigate = useNavigate();
    let { markingId } = useParams();
    const [marking, setMarking] = useState<Marking | null>(null);

    useEffect(() => {}, []);

    useEffect(() => {
        if (markingId !== undefined) {
            MarkingConnector.getMarking(Number(markingId)).then((fetchedMarking) => {
                setMarking(fetchedMarking);
            });
        }
    }, [markingId]);

    return (
        <Page>
            <Section>
                <BackButton onClick={() => navigate(-1)}>X</BackButton>
                <StyledImage src="/img/gol-logo.svg" alt="Logo" />
                <Title>Rail Marking</Title>
            </Section>
            <BodySection>
                <ImageContainer>
                    <MarkingImage src={marking?.imageUrl} alt="marking-img" />
                </ImageContainer>
                <DetailsContainer>
                    <Label>Rail grade (CEN):</Label>
                    <Value>{marking?.grade}</Value>
                    <Label>Former rail grade: </Label>
                    <Value>{marking?.formerGrade || '-'}</Value>
                    <Label>Brand: </Label>
                    <Value>{marking?.brand || '-'}</Value>
                    <Label>Portion grade: </Label>
                    <Value>{marking?.portion || '-'}</Value>
                    <StrongLabel>Chemical composition: </StrongLabel>
                    {marking?.composition ? (
                        marking?.composition.split('|').map((composition, index) => {
                            const parts = composition.split(':');
                            return (
                                <Row key={index}>
                                    <div>{parts[0]}:</div>
                                    <Value>{parts[1]}</Value>
                                </Row>
                            );
                        })
                    ) : (
                        <Row>Chemical composition details have not been provided.</Row>
                    )}
                </DetailsContainer>
            </BodySection>
        </Page>
    );
};

export default RailMarkingDetailsPage;
