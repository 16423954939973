import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import WeldDefectConnector, { WeldDefect } from '../../../connectors/WeldDefectConnector';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const MarkingImage = styled.img`
    width: 80%;
    max-width: 400px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const BodySection = styled(Section)`
    width: 100%;
    padding-top: 0;
    overflow-y: auto;
`;

const Label = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
`;

const Value = styled.div`
    display: flex;
    align-items: center;
`;

const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const DetailsContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
`;

const BackButton = styled.button`
    position: absolute;
    right: 15px;
    top: 60px;
    border: none;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
`;

const WeldDefectDetailsPage = () => {
    const navigate = useNavigate();
    let { weldDefectId } = useParams();
    const [weldDefect, setWeldDefect] = useState<WeldDefect | null>(null);

    useEffect(() => {}, []);

    useEffect(() => {
        if (weldDefectId !== undefined) {
            WeldDefectConnector.getWeldDefect(Number(weldDefectId)).then((fetchedWeldDefect) => {
                setWeldDefect(fetchedWeldDefect);
            });
        }
    }, [weldDefectId]);

    return (
        <Page>
            <Section>
                <BackButton onClick={() => navigate(-1)}>X</BackButton>
                <StyledImage src="/img/gol-logo.svg" alt="Logo" />
                <Title>Weld Defect</Title>
            </Section>
            <BodySection>
                <ImageContainer>
                    {weldDefect?.imageUrl && <MarkingImage src={weldDefect?.imageUrl} alt="marking-img" />}
                </ImageContainer>
                <DetailsContainer>
                    <Label>Defect Code:</Label>
                    <Value>{weldDefect?.code}</Value>
                    <Label>Weld Feature: </Label>
                    <Value>{weldDefect?.weldFeature || '-'}</Value>
                    <Label>Inspection: </Label>
                    <Value>{weldDefect?.inspection || '-'}</Value>
                    <Label>Criteria: </Label>
                    <Value>{weldDefect?.criteria || '-'}</Value>
                </DetailsContainer>
            </BodySection>
        </Page>
    );
};

export default WeldDefectDetailsPage;
